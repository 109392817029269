<template>
  <v-dialog
    v-model="modalState"
    transition="dialog-bottom-transition"
    persistent
    width="600"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Order Update</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mt-4">
        <v-row class="pa-0">
          <v-col cols="12" class="my-0 py-0">
            <v-autocomplete
              label="Carrier"
              v-model="formData.carrierId"
              :items="serverData.carriers"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-autocomplete
              label="Carrier Account"
              v-model="formData.carrier_account_id"
              :items="serverData.carrier_accounts"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <v-autocomplete
              label="Payment Type"
              v-model="formData.paymenttypeId"
              :items="serverData.paymentTypes"
              item-text="text"
              item-value="index"
              dense
              outlined
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field
              label="Delivery Number"
              v-model="formData.deliveryNo"
              dense
              outlined
              clearable
            />
          </v-col>
          <v-col cols="12" sm="6" class="my-0 py-0">
            <v-text-field
              label="Number Of Boxes"
              v-model="formData.numberOfBoxes"
              dense
              outlined
              clearable
              type="number"
              @change="() => validateMinValue('staff_account_limit', 0)"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <div
              class="col-12 d-flex align-center justify-space-between mt-0 pt-0"
            >
              <h5 class="mb-0 mt-0 pt-0 font-weight-regular">RTS</h5>
              <v-switch
                :label="rts_flag_label"
                v-model="formData.rts_flag"
                dense
                class="mt-0 pt-0"
                hide-details
                :true-value="1"
                :false-value="0"
              />
            </div>
          </v-col>
        </v-row>
        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            @click.prevent="clearFields"
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
          >
            Clear
          </button>
          <button
            @click.prevent="submitStatusUpdate"
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
    </div>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "Update3PLAWBModal",
  props: {
    refresher: { required: true, type: Function },
  },
  mixins: [fieldValueValidation],
  data: () => ({
    modalState: false,
    formData: {
      ordersId: null,
      carrierId: null,
      paymenttypeId: null,
      deliveryNo: null,
      numberOfBoxes: null,
      rts_flag: null,
      carrier_account_id: null,
    },
    carriers: [],
    paymentType: [],
    carrier_accounts: [],
  }),
  computed: {
    rts_flag_label() {
      return this.formData.rts_flag === 1 ? "Yes" : "No";
    },
    serverData: function () {
      // return this.serverData;
      return {
        carriers: this.carriers,
        paymentTypes: this.paymentType,
        carrier_accounts: this.carrier_accounts,
      };
    },
  },
  methods: {
    toggleModal(data) {
      if (!this.modalState) {
        this.setDataFromServer(data);
      } else this.clearFields();
      this.modalState = !this.modalState;
    },
    setDataFromServer(data) {
      this.carriers = [...data.carriers];
      this.paymentType = [...data.paymentType];
      this.carrier_accounts = [...data.carrier_accounts];

      this.formData.ordersId = data.order.id;
      this.formData.carrierId = data.order.carrierId;
      this.formData.paymenttypeId = data.order.paymenttypeId;
      this.formData.deliveryNo = data.order.deliveryNo;
      this.formData.numberOfBoxes = data.order.numberOfBoxes;
      this.formData.rts_flag = data.order.rts_flag;
      this.formData.carrier_account_id = data.order.carrier_account_id;
    },
    submitStatusUpdate() {
      this.$store.commit(SET_PAGE_LOADING, true);

      ApiService.post(`/fulfillment/orders/update_3pl_awb_save`, {
        ...this.formData,
      }).then(() => {
        Swal.fire({
          title: "Updated",
          text: "Order is updated",
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        this.refresher();
        this.toggleModal();
      });
    },
    clearFields() {
      this.formData = {
        ordersId: null,
        carrierId: null,
        paymenttypeId: null,
        deliveryNo: null,
        numberOfBoxes: null,
        rts_flag: null,
        carrier_account_id: null,
      };
    },
  },
};
</script>
