<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1200px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          Edit Order ({{ serverData.data[1].data[1].value }})
        </h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-block pa-4">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              :editable="isTabEditable(item)"
              :complete="isTabEditable(item)"
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" class="pt-2">
              <v-card color="basil" flat>
                <pop-up-time-line :item="getItemData('timeline')" />
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-2">
              <v-card color="basil" flat class="row">
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="$v.consignee.consignee_name.$model"
                    label="Consignee name"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('consignee_name', 'consignee')
                    "
                    @input="$v.consignee.consignee_name.$touch()"
                    @blur="$v.consignee.consignee_name.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.consignee.consignee_phone.$model"
                    label="Consignee phone"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('consignee_phone', 'consignee')
                    "
                    @input="$v.consignee.consignee_phone.$touch()"
                    @blur="$v.consignee.consignee_phone.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.consignee.consignee_mobile.$model"
                    label="Consignee mobile"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('consignee_mobile', 'consignee')
                    "
                    @input="$v.consignee.consignee_mobile.$touch()"
                    @blur="$v.consignee.consignee_mobile.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="consignee.consignee_email"
                    label="Consignee email"
                    clearable
                    outlined
                    dense
                  />
                </div>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2">
              <v-card color="basil" flat class="row">
                <div class="col-12 col-sm-6 col-md-4" v-if="!isClient">
                  <v-autocomplete
                    v-model="order_details.customer_id"
                    label="Customer"
                    item-text="text"
                    item-value="index"
                    :items="serverData.customers"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('customer_id', 'order_details')
                    "
                    @input="$v.order_details.customer_id.$touch()"
                    @blur="$v.order_details.customer_id.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.warehouse"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.order_type"
                    label="Order type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.order_types"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.carrier_option"
                    label="Carrier option"
                    item-text="text"
                    item-value="index"
                    :items="serverData.carrier_options"
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    class
                    v-model="order_details.awb"
                    label="AWB"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-autocomplete
                    v-model="order_details.shipping_method"
                    label="Shipping method"
                    item-text="text"
                    item-value="index"
                    :items="serverData.shipping_types"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('shipping_method', 'order_details')
                    "
                    @input="$v.order_details.shipping_method.$touch()"
                    @blur="$v.order_details.shipping_method.$touch()"
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-6">
                  <v-autocomplete
                    v-model="order_details.payment_type"
                    label="Payment Type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.payment_types"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('payment_type', 'order_details')
                    "
                    @input="$v.order_details.payment_type.$touch()"
                    @blur="$v.order_details.payment_type.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-6">
                  <v-autocomplete
                    v-model="order_details.currency"
                    label="Currency"
                    item-text="text"
                    item-value="index"
                    :items="serverData.currencies"
                    clearable
                    outlined
                    dense
                    :error-messages="
                      handleFormValidation('currency', 'order_details')
                    "
                    @input="$v.order_details.currency.$touch()"
                    @blur="$v.order_details.currency.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    class
                    v-model="order_details.total_price"
                    type="number"
                    label="Total price"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('total_price', 0, 'order_details')
                    "
                    :error-messages="
                      handleFormValidation('total_price', 'order_details')
                    "
                    @input="$v.order_details.total_price.$touch()"
                    @blur="$v.order_details.total_price.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.parcel_weight"
                    label="Parcel weight"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="
                      handleFormValidation('parcel_weight', 'order_details')
                    "
                    @input="$v.order_details.parcel_weight.$touch()"
                    @blur="$v.order_details.parcel_weight.$touch()"
                    @change="
                      () =>
                        validateMinValue('parcel_weight', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.parcel_volume"
                    label="Parcel volume"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="
                      handleFormValidation('parcel_volume', 'order_details')
                    "
                    @input="$v.order_details.parcel_volume.$touch()"
                    @blur="$v.order_details.parcel_volume.$touch()"
                    @change="
                      () =>
                        validateMinValue('parcel_volume', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.number_of_boxes"
                    label="Number of boxes"
                    :min="0"
                    clearable
                    outlined
                    dense
                    type="number"
                    :error-messages="
                      handleFormValidation('number_of_boxes', 'order_details')
                    "
                    @input="$v.order_details.number_of_boxes.$touch()"
                    @blur="$v.order_details.number_of_boxes.$touch()"
                    @change="
                      () =>
                        validateMinValue('number_of_boxes', 0, 'order_details')
                    "
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.shipping_price"
                    label="Shipping price"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('shipping_price', 0, 'order_details')
                    "
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.customs_value"
                    label="Customs value"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('customs_value', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.subtotal"
                    label="Subtotal"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('subtotal', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.total_discount"
                    label="Total discount"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('total_discount', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.total_tax"
                    label="Total tax"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('total_tax', 0, 'order_details')
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.tax_percentage"
                    label="Tax percentage"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue('tax_percentage', 0, 'order_details')
                    "
                  />
                </div>

                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.total_declared_amount"
                    label="Total declared amount"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () =>
                        validateMinValue(
                          'total_declared_amount',
                          0,
                          'order_details'
                        )
                    "
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3">
                  <v-text-field
                    v-model="order_details.cod_amount"
                    label="Cod amount"
                    type="Number"
                    :min="0"
                    clearable
                    outlined
                    dense
                    @change="
                      () => validateMinValue('cod_amount', 0, 'order_details')
                    "
                    :error-messages="
                      handleFormValidation('cod_amount', 'order_details')
                    "
                    @input="$v.order_details.cod_amount.$touch()"
                    @blur="$v.order_details.cod_amount.$touch()"
                  />
                </div>

                <div class="col-12">
                  <v-text-field
                    class
                    v-model="order_details.comment"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  />
                </div>

                <div class="col-12">
                  <v-text-field
                    v-model="order_details.gift_message"
                    label="Gift message"
                    clearable
                    outlined
                    dense
                  />
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3" class="pt-2">
              <v-card color="basil" flat>
                <pop-up-table :item="getItemData('table')" />
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="4" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <v-autocomplete
                    @change="onCountryValueChange"
                    v-model="$v.address.country_id.$model"
                    label="Country"
                    item-text="text"
                    item-value="index"
                    :items="serverData.countries"
                    clearable
                    outlined
                    dense
                    :error-messages="country_idErrors"
                    @input="$v.address.country_id.$touch()"
                    @blur="$v.address.country_id.$touch()"
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="stateCombo"
                    v-model="$v.address.state.$model"
                    :search-input.sync="state_text"
                    :items="serverData.states"
                    :disabled="isStatesLoaded"
                    item-text="title"
                    item-value="title"
                    label="State"
                    outlined
                    required
                    dense
                    :rules="
                      validationValueCheck('state')
                        ? [validationValueCheck('state'), oldValueText('state')]
                        : [oldValueText('state')]
                    "
                    :error-messages="stateErrors"
                    @input="$v.address.state.$touch()"
                    @blur="$v.address.state.$touch()"
                    @change="onStateValueChange"
                    @keyup="updateValue('state')"
                  >
                    <template v-slot:append-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'state',
                                    address.country_id,
                                    onCountryValueChange,
                                    state_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="cityCombo"
                    v-model="$v.address.city.$model"
                    :search-input.sync="city_text"
                    :items="serverData.cities"
                    :disabled="isCitiesLoaded"
                    item-text="title"
                    item-value="title"
                    label="City"
                    persistent-hint
                    outlined
                    dense
                    :rules="
                      validationValueCheck('city')
                        ? [validationValueCheck('city'), oldValueText('city')]
                        : [oldValueText('city')]
                    "
                    :error-messages="cityErrors"
                    @input="$v.address.city.$touch()"
                    @blur="$v.address.city.$touch()"
                  >
                    <template v-slot:append-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'city',
                                    address.state.id,
                                    onStateValueChange,
                                    city_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="areaCombo"
                    :disabled="isAreasLoaded"
                    v-model="address.area"
                    :search-input.sync="area_text"
                    :items="serverData.areas"
                    item-text="title"
                    item-value="title"
                    label="Area"
                    persistent-hint
                    outlined
                    dense
                    :rules="
                      validationValueCheck('area')
                        ? [validationValueCheck('area'), oldValueText('area')]
                        : [oldValueText('area')]
                    "
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'area',
                                    address.city.id,
                                    onCityValueChange,
                                    area_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>

                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="address.province"
                    label="Province"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="address.town"
                    label="town"
                    clearable
                    outlined
                    dense
                  />
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.address.address.$model"
                    label="Address"
                    clearable
                    outlined
                    dense
                    :error-messages="handleFormValidation('address', 'address')"
                    @input="$v.formData.address.$touch()"
                    @blur="$v.formData.address.$touch()"
                  />
                </div>

                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="address.zipcode"
                    label="Zipcode"
                    clearable
                    outlined
                    dense
                  />
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="5" class="pt-2">
              <v-card color="basil" flat>
                <pop-up-text-group :item="getItemData('text')" />
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="6" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :skuItem="serverData.skus"
                    :clientId="serverData.order.client_id"
                  />
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!--end::Body-->

        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        />

        <div
          class="d-flex align-center justify-center justify-sm-space-between modal-action flex-wrap pt-4"
        >
          <v-btn
            width="120"
            @click="() => showPrevOrder(serverData.order.id)"
            class="order-4 order-sm-1"
          >
            previous
          </v-btn>

          <button
            type="submit"
            class="btn btn-info px-5 py-3 order-1 order-sm-4"
            @click="submitEditForm"
          >
            Update
          </button>
          <v-btn
            width="120"
            @click="() => showNextOrder(serverData.order.id)"
            class="order-5 order-sm-5"
          >
            next
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog2" scrollable max-width="300px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider />
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            />
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason === 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="holdOn_date" @input="menu2 = false" />
          </v-menu>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="() => (dialog2 = false)">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable max-width="300px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider />
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="() => (dialog3 = false)">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
const EDIT_ENDPOINT = "/fulfillment/orders/edit";
const UPDATE_ENDPOINT = "/fulfillment/orders/update";

import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/orderManagement/pendingAuditOrders/skuContainer/SkuDatatable";

import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine";
import PopUpTable from "@/own/components/fulfillment/orders/popup/PopUpTable";
import PopUpTextGroup from "@/own/components/fulfillment/orders/popup/PopUpTextGroup";

import CreateLocation from "./CreateLocation.vue";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

import {
  handleCityValueChange,
  handleCountryValueChange,
  handleStateValueChange,
} from "@/own/libs/handle-locations";
import { fetchEditFormData, submitEditFormData } from "@/own/api/edit-form";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    PopUpTextGroup,
    PopUpTimeLine,
    SkuDatatable,
    PopUpTable,
    CreateLocation,
  },
  props: {
    itemForAction: {
      required: false,
      type: Number,
    },
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    extraData: {
      required: true,
      type: Object,
    },
    showNextOrder: {
      required: true,
      type: Function,
    },
    showPrevOrder: {
      required: true,
      type: Function,
    },
  },
  validations() {
    return {
      address: {
        address: { required },
        city: { required },
        state: { required },
        country_id: { required },
      },
      consignee: {
        consignee_name: { required },
        consignee_phone: { required },
        consignee_mobile: { required },
      },
      order_details: {
        customer_id: {
          required: requiredIf(function () {
            return !this.isClient;
          }),
        },
        shipping_method: { required },
        payment_type: { required },
        currency: { required },
        total_price: { required },
        parcel_weight: {
          required: requiredIf(function () {
            return (
              this.order_details.order_type === 9 ||
              this.order_details.order_type === 1
            );
          }),
        },
        parcel_volume: {
          required: requiredIf(function () {
            return (
              this.order_details.order_type === 9 ||
              this.order_details.order_type === 1
            );
          }),
        },
        number_of_boxes: {
          required: requiredIf(function () {
            return (
              this.order_details.order_type === 9 ||
              this.order_details.order_type === 17
            );
          }),
        },
        cod_amount: {
          required: requiredIf(function () {
            return this.order_details.payment_type === 1;
          }),
        },
      },
    };
  },
  data: () => ({
    state_text: null,
    city_text: null,
    area_text: null,

    location_type: null,
    locaiton_id: null,
    location_function: null,
    location_initValue: null,

    old_address: {
      state: null,
      city: null,
      area: null,
    },

    formData: {
      id: null,

      skus: [],
    },
    address: {
      address: null,
      area: null,
      province: null,
      town: null,
      city: null,
      state: null,
      country_id: null,
      zipcode: null,
    },
    consignee: {
      consignee_name: null,
      consignee_phone: null,
      consignee_mobile: null,
      consignee_email: null,
    },
    order_details: {
      awb: null,
      customer_id: null,
      warehouse: null,
      total_price: null,
      customs_value: null,
      subtotal: null,
      total_discount: null,
      total_tax: null,
      carrier_option: "3PL",

      order_type: null,
      payment_type: null,
      currency: null,
      shipping_method: null,
      comment: null,
      parcel_weight: null,
      parcel_volume: null,
      number_of_boxes: null,
      gift_message: null,
      shipping_price: null,
      tax_percentage: null,
      total_declared_amount: null,
      cod_amount: null,
    },

    dialog2: false,
    dialog3: false,
    dialog: false,
    menu2: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    serverData: null,
    tab: 0,

    updateValueTimer: setTimeout(() => {}, 0),
    cityFetchTimer: null,
    areaFetchTimer: null,
    locationFetchTriggerTime: 600,
    categories: [
      {
        name: "Status History",
        valid: true,
        rules: () => {
          return false;
        },
      },
      {
        name: "Consignee Details",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`consignee`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "Order Details",
        valid: true,
        rules: (el) => {
          try {
            return el[`$v`][`order_details`].$invalid;
          } catch {
            return true;
          }
        },
      },
      {
        name: "Call History",
        valid: true,
        rules: () => {
          return false;
        },
      },
      {
        name: "Address",
        valid: true,
        rules: (el) => {
          try {
            return (
              el[`$v`][`address`].$invalid ||
              this.validationValueCheck("state") ||
              this.validationValueCheck("city")
            );
          } catch {
            return true;
          }
        },
      },
      {
        name: "Current Status",
        valid: true,
        rules: () => {
          return false;
        },
      },
      {
        name: "Items",
        valid: true,
        rules: (el) => {
          try {
            return !el.formData.skus.length > 0;
          } catch {
            return true;
          }
        },
      },
    ],
    has_update: false,
    cache_data: null,
    // ckeditorType: ClassicEditor,
  }),
  methods: {
    updateValue(type) {
      clearTimeout(this.updateValueTimer);
      this.updateValueTimer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, this.locationFetchTriggerTime);
    },
    oldValueText(type) {
      let message = "";
      switch (type) {
        case "state":
          if (this.old_address.state) {
            message = `Received: ${this.old_address.state}`;
          }

          break;
        case "city":
          if (this.old_address.city) {
            message = `Received: ${this.old_address.city}`;
          }
          break;
        case "area":
          if (this.old_address.area) {
            message = `Received: ${this.old_address.area}`;
          }
          break;
      }
      return message;
    },
    async onBlurValueCheck(type) {
      switch (type) {
        case "state": {
          if (this.state_text == "") {
            return;
          }

          const item = this.serverData?.states?.find(
            (state) => state.title === this.state_text
          );

          if (!item) {
            this.serverData.cities = null;
            this.serverData.areas = null;
          } else {
            if (item.title != this.state_text) {
              this.state_text = item.title;
            } else {
              await this.onStateValueChange(item.title);
              this.$refs.stateCombo.blur();
            }
          }

          break;
        }
        case "city": {
          if (this.city_text == "") {
            return;
          }

          const item = this.serverData?.cities?.find(
            (city) => city.title === this.city_text
          );
          if (!item) {
            this.serverData.areas = null;
          } else {
            if (item.title != this.city_text) {
              this.city_text = item.title;
            } else {
              await this.onCityValueChange(item.title);
              this.$refs.cityCombo.blur();
            }
          }

          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = null;
      switch (type) {
        case "state":
          if (this.state_text == "") {
            return;
          }
          try {
            if (
              !this.serverData?.states?.find(
                (state) => state.title === this.state_text
              )
            ) {
              validation = "Please, select valid state!";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;
        case "city":
          if (this.city_text == "") {
            return;
          }
          try {
            if (
              !this.serverData?.cities?.find(
                (city) => city.title === this.city_text
              )
            ) {
              validation = "Please, select valid city!";
              // this.serverData.areas = null;
            }
          } catch {
            validation = "Please, select valid state!";
          }

          break;
        case "area":
          try {
            if (
              !this.serverData.areas.find(
                (area) => area.title === this.area_text
              )
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            validation = "Please, select valid city!";
          }
          break;
      }
      return validation;
    },
    setTextValue(type, value = "") {
      switch (type) {
        case "state":
          this.state_text = value;
          if (value == "") {
            return;
          }
          const state = this.serverData?.states?.find(
            (state) => state.title === this.state_text
          );
          this.address.state = state;
          // this.onStateChange(this.address.state.title);

          break;
        case "city":
          this.city_text = value;
          if (value == "") {
            return;
          }
          const city = this.serverData?.cities?.find(
            (city) => city.title === this.city_text
          );
          if (city) {
            this.address.city = city;
          }

          // this.onCityChange(this.address.city.title);

          break;
        case "area":
          this.area_text = value;

          if (value == "") {
            return;
          }
          const area = this.serverData?.areas?.find(
            (area) => area.title === this.area_text
          );
          if (area) {
            this.address.area = area;
          }

          break;
      }
    },
    createNewItem(type, id, func, value) {
      this.$refs[`${type}Combo`].blur();
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    getItemData(type) {
      return this.infoData.find((data) => data.type === type);
    },
    actionUpdateAndAudit() {},
    showCancelModal() {
      this.dialog3 = !this.dialog3;
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoader(true);
        this.dialog3 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    showHoldOnModal() {
      this.dialog2 = !this.dialog2;
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoader(true);
        this.dialog2 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Hold On reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      const response = await fetchEditFormData(
        { id: this.itemForAction },
        EDIT_ENDPOINT
      );
      if (response) {
        this.serverData = response;
        await this.resetEditForm();
        this.updateValidationStateOfSteps();
      } else {
        this.dialog = false;
      }
      this.pageLoader(false);
    },
    validateSkuQuantity() {
      if (this.formData.skus.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one item should be added</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      } else {
        return true;
      }
    },
    validateAddressData() {
      if (
        this.validationValueCheck("state") ||
        this.validationValueCheck("city")
      ) {
        Swal.fire({
          title: "Warning",
          html: `<div>Invalid address!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return false;
      } else {
        return true;
      }
    },
    validateEditForm() {
      this.updateValidationStateOfSteps();
      if (this.$v.$invalid) {
        this.$v.$touch();
        return false;
      }
      this.$v.$touch();
      if (!this.validateSkuQuantity()) return false;
      if (!this.validateAddressData()) return false;
      return true;
    },
    async submitEditForm() {
      if (!this.validateEditForm()) return;
      this.pageLoader(true);
      let data = {
        ...this.formData,
        ...this.address,
        ...this.consignee,
        ...this.order_details,
      };
      data.state = this.address.state.id;
      data.city = this.address.city.id;
      if (this.address.area) {
        data.area = this.address.area.id;
      }
      Object.entries(data).forEach(([key, value]) => {
        if (key === "state" && value) {
          const state = this.serverData.states.find(
            (state) => state.id === value
          );
          state?.title !== this.cache_data.state.title &&
            (this.has_update = true);
        } else if (key === "city" && value) {
          const city = this.serverData.cities.find((city) => city.id === value);
          city?.title !== this.cache_data.city.title &&
            (this.has_update = true);
        } else if (key === "area" && value) {
          if (this.serverData?.areas?.length) {
            const area = this.serverData.areas.find(
              (area) => area.id === value
            );
            area?.title !== this.cache_data.area.title &&
              (this.has_update = true);
          }
        } else if (key === "skus" && value) {
          if (this.cache_data.skus.length === value.length) {
            this.cache_data.skus.map((sku, index) => {
              if (
                sku.skuId !== value[index].skuId ||
                sku.quantity !== value[index].quantity
              ) {
                this.has_update = true;
              }
            });
          } else {
            this.has_update = true;
          }
        } else {
          if (value && this.cache_data[key] !== data[key]) {
            this.has_update = true;
          }
        }
      });

      data.has_update = this.has_update;
      const response = await submitEditFormData(data, UPDATE_ENDPOINT);
      if (response) {
        Swal.fire({
          title: "Updated",
          text: `Order has been updated`,
          icon: "success",
          showConfirmButton: false,
          timer: 2500,
        });
        let id = this.serverData.order.id;
        this.pageLoader(false);
        this.showNextOrder(id);
      }
      this.pageLoader(false);
    },

    resetAllDataToNull() {
      this.formData = {
        id: null,
        skus: [],
      };
      this.address = {
        address: null,
        area: null,
        province: null,
        town: null,
        city: null,
        state: null,
        country_id: null,
        zipcode: null,
      };
      this.consignee = {
        consignee_name: null,
        consignee_phone: null,
        consignee_mobile: null,
        consignee_email: null,
      };
      this.order_details = {
        awb: null,
        customer_id: null,
        warehouse: null,
        total_price: null,
        customs_value: null,
        subtotal: null,
        total_discount: null,
        total_tax: null,
        carrier_option: "3PL",

        order_type: null,
        payment_type: null,
        currency: null,
        shipping_method: null,
        comment: null,
        parcel_weight: null,
        parcel_volume: null,
        number_of_boxes: null,
        gift_message: null,
        shipping_price: null,
        tax_percentage: null,
        total_declared_amount: null,
        cod_amount: null,
      };
    },
    async copyDataToLocal(dataName) {
      let copy = { ...this.serverData.order };
      await Object.entries(this[dataName]).forEach((localD) => {
        Object.entries(this.serverData.order).forEach((serverD) => {
          if (
            localD[0] === serverD[0] &&
            localD[0] !== "image" &&
            localD[0] !== "photo" &&
            localD[0] !== "picture"
          ) {
            this[dataName][localD[0]] = copy[serverD[0]];
          }
        });
      });
    },
    async resetEditForm() {
      this.$v.$reset();
      const dataNames = ["formData", "address", "consignee", "order_details"];
      if (this.serverData) {
        await Promise.all(dataNames.map(this.copyDataToLocal.bind(this)));
        this.old_address = {
          state: null,
          city: null,
          area: null,
        };
        this.old_address = {
          state: this.serverData?.order?.state,
          city: this.serverData?.order?.city,
          area: this.serverData?.order?.area,
        };
        // this.address.state = null;
        // this.address.city = null;
        // this.address.area = null;

        if (!this.serverData.order.country_id) return;
        await this.onCountryValueChange(this.serverData.order.country_id, true);

        if (!this.serverData.order.state) return;
        this.old_address["state"] = this.serverData.order.state;
        await this.onStateValueChange(this.serverData.order.state, true);

        if (!this.serverData.order.city) return;
        this.old_address["city"] = this.serverData.order.city;
        await this.onCityValueChange(this.serverData.order.city, true);

        if (!this.serverData.order.area) return;
        this.old_address["area"] = this.serverData.order.area;

        this.cache_data = {
          ...this.formData,
          ...this.address,
          ...this.consignee,
          ...this.order_details,
        };
        this.has_update = false;
      } else {
        this.resetAllDataToNull();
      }

      this.tab = 0;
      this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.dialog = !this.dialog;
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
        this.dialog = !this.dialog;
      }
    },
    async onCountryValueChange(val, safe = true) {
      this.pageLoader(true);
      try {
        await handleCountryValueChange.call(this, val, safe);
        await this.updateValue("state");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onStateValueChange(value, safe = true, is_id = false) {
      this.pageLoader(true);
      try {
        await handleStateValueChange.call(this, value, safe, is_id);
        await this.updateValue("city");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onCityValueChange(value, safe = true, is_id = false) {
      this.pageLoader(true);
      try {
        await handleCityValueChange.call(this, value, safe, is_id);
        this.areaMessageGenerator();
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    handleFormValidation(fieldName, dataName = "formData") {
      const errors = [];
      if (!this.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    isTabEditable: function (item) {
      if (
        (item === "Items" && this.serverData.order.lastStatus === "Picking") ||
        this.serverData.order.lastStatus === "Packing"
      ) {
        return false;
      }
      return true;
    },
  },
  computed: {
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    infoData() {
      return this.serverData.data;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    cityErrors: function () {
      return this.handleFormValidation("city", "address");
    },
    country_idErrors: function () {
      return this.handleFormValidation("country_id", "address");
    },
    stateErrors: function () {
      return this.handleFormValidation("state", "address");
    },
    // warehouse_idErrors: function () {
    //   return this.handleFormValidation("warehouse_id", "order_details");
    // },
  },
  watch: {
    state_text: {
      handler(value, oldValue) {
        if (!this.serverData.countries) {
          this.serverData.states = null;
          this.serverData.cities = null;
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("state");
      },
      deep: true,
    },
    city_text: {
      handler(value, oldValue) {
        if (!this.serverData.states) {
          this.serverData.cities = null;
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("city");
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  align-content: flex-end;
}

.modal-root {
  background-color: white !important;
  height: 100%;
}

.modal-action {
  justify-self: flex-end;
  height: 100%;
}

.form-body {
  max-height: 80vh !important;
  height: 80vh !important;
  overflow-y: scroll;
}

.step-head {
}

.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
