<template>
  <div v-if="table_permissions" class="custom-content-height">
    <v-data-table
      v-model="selected"
      fixed-header
      light
      dense
      :height="getvh(52)"
      :show-select="!disableSelectAllCheckbox"
      :single-select="disableSelectAllCheckbox"
      :items="items"
      :headers="headers"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortOrder"
      :items-per-page="itemPerPage"
      item-key="id.id"
      class="mx-2 mt-0 font-weight-bold"
      hide-default-footer
      @dblclick:row="showDetail"
      mobile-breakpoint="40"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE!</template>
      <template v-slot:no-results> NO RESULTS HERE!</template>
      <!--    no-data end-->
      <!--    table top-->
      <template v-slot:top>
        <div
          class="ma-0 mb-10 pa-0 pt-2 d-flex justify-space-between align-center flex-wrap bg-white position-sticky top-0 zindex-5 row"
        >
          <div class="col-12 col-md-1 px-0">
            <datatable-detail
              :table_properties="table_properties"
              grid="col-12"
              v-if="$vuetify.breakpoint.mdAndUp"
            ></datatable-detail>
            <div
              v-else
              class="d-flex align-center justify-space-between mb-3 w-100"
            >
              <datatable-detail
                :table_properties="table_properties"
              ></datatable-detail>
              <b-button
                depressed
                small
                variant="outline-primary"
                class="mb-1 btn--tools btn-sm"
                @click="toolBarVisionState = !toolBarVisionState"
              >
                TOOLS
              </b-button>
            </div>
          </div>

          <div class="col-12 col-md-11">
            <div
              v-show="isToolbarVisiable"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              class="card-toolbar ma-0 pa-0 row justify-content-start justify-content-sm-end justify-content-md-end align-center align-content-center"
            >
              <!--begin::Toll buttons-->
              <div class="row justify-content-xl-end">
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('export')"
                >
                  <button
                    @click="$refs.exportSidebar.$data.exportSidebar = true"
                    class="btn btn--export-filter w-100"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-export-variant</v-icon>
                    </span>
                    Export
                  </button>
                </div>
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="filters.length > 0"
                >
                  <button
                    @click="$refs.filterSidebar.$data.filterSidebar = true"
                    class="btn btn--export-filter w-100"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-filter</v-icon>
                    </span>
                    Filter
                  </button>
                </div>
                <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                  <b-dropdown
                    ref="dropdown"
                    id="dropdown-1"
                    :text="getQFilterStatusLabel"
                    class="ma-0 pa-0 w-100"
                    toggle-class="poppins btn--export-filter bg-hover-info"
                    menu-class="m-0 p-0 "
                    variant="info"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <div class="px-2">
                        <span class="svg-icon">
                          <v-icon size="18">mdi-filter</v-icon>
                        </span>
                        <span>{{ getQFilterStatusLabel }}</span>
                        <span class="svg-icon mr-0 ml-2">
                          <v-icon size="18">mdi-chevron-down</v-icon>
                        </span>
                      </div>
                    </template>
                    <div class="q-status-menu py-1 w-175px">
                      <div
                        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer mt-2"
                        @click="() => onStatusFilterChange(null)"
                      >
                        <div
                          class="py-2 w-100 text-left drop-text poppins font-size-sm"
                        >
                          All
                        </div>
                      </div>
                      <div
                        class="drop-item poppins px-3 mx-3 rounded-lg cursor-pointer"
                        v-for="data in status_filter_data"
                        :value="data.index"
                        :key="data.index"
                        @click="() => onStatusFilterChange(data.index)"
                      >
                        <div
                          class="py-2 w-100 text-left drop-text poppins font-size-sm"
                        >
                          {{ data.text }}
                        </div>
                      </div>
                    </div>
                  </b-dropdown>
                </div>
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('update_order_status')"
                >
                  <button
                    class="btn btn--print-upload w-100"
                    @click.prevent="updateStatus(null, true)"
                  >
                    <span class="svg-icon d-none d-sm-inline-block">
                      <v-icon size="18">mdi-refresh</v-icon>
                    </span>
                    Update Status
                  </button>
                  <ChangeStatus
                    :refresher="updateTableContent"
                    :pageLoader="pageLoad"
                    ref="changeStatus"
                    :ids="updateStatusItemId"
                    :isMultiple="getIsUpdateStatusItemsMultiple"
                  ></ChangeStatus>
                </div>
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('upload')"
                >
                  <button
                    class="btn btn--print-upload w-100"
                    @click.prevent="showUploadModal"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-cloud-upload</v-icon>
                    </span>
                    Import
                  </button>
                </div>
                <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('create')"
                >
                  <button
                    class="btn btn--print-upload w-100"
                    @click.prevent="showAddItemModal"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-package-variant</v-icon>
                    </span>
                    Create Order
                  </button>
                  <AddItem
                    :refresher="updateTableContent"
                    :pageLoader="pageLoad"
                    ref="addItem"
                  ></AddItem>
                </div>
                <!-- <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('order_rvp_create')"
                >
                  <button
                    @click="createRVP"
                    class="btn btn--print-upload w-100"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-package-variant</v-icon>
                    </span>
                    Create RVP
                  </button>
                </div> -->
                <!-- <div
                  class="col-6 col-sm-4 col-md-auto px-1 pt-md-0"
                  v-if="permissionChecker('order_rto_create')"
                >
                  <button
                    @click="createRTO"
                    class="btn btn--print-upload w-100"
                  >
                    <span class="svg-icon">
                      <v-icon size="18">mdi-package-variant</v-icon>
                    </span>
                    Create RTO
                  </button>
                </div> -->
                <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                  <Print :items="selected" :types="types" class="w-100"></Print>
                </div>
                <div class="col-6 col-sm-4 col-md-auto px-1 pt-md-0">
                  <ActionsButton
                    v-if="actionsButtonTypes.length > 0"
                    :items="selected"
                    :types="actionsButtonTypes"
                    :refresher="updateTableContent"
                    :class="{ 'col-6': $vuetify.breakpoint.xsOnly }"
                    :callback="{
                      return_order_asn_create: toggelCreateAsnModal,
                    }"
                  ></ActionsButton>
                </div>
              </div>
              <!--end::Toll buttons-->
            </div>
          </div>
        </div>
        <!-- Export Sidebar start -->
        <ExportSidebar
          v-if="exportHeaders.length > 0"
          ref="exportSidebar"
          :exportHeaders="exportHeaders"
          :downloadItem="downloadItem"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--        end::Export Sidebar-->

        <!-- Filter Sidebar start -->
        <FilterSidebar
          v-if="filters.length > 0"
          ref="filterSidebar"
          :submitFilterValues="submitFilterValues"
          :resetFilterValues="resetFilterValues"
          :filters="filters"
          :setTableFiltersValues="setTableFiltersValues"
        />
        <!--end::Filter Sidebar-->

        <!--        <hr />-->
        <Others ref="otherData"></Others>
      </template>
      <!-- column -->
      <template
        v-if="!disableSelectAllCheckbox"
        v-slot:header.data-table-select="{ props: { value }, on: { input } }"
      >
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="allSelected"
            @click="selectAll"
          />
        </div>
      </template>

      <template v-slot:item.data-table-select="{ item }">
        <div
          class="form-check form-check-sm form-check-custom form-check-solid mr-5 min-w-20px min-h-20px"
        >
          <input
            class="form-check-input"
            type="checkbox"
            v-model="selected"
            @click="selectOne"
            :value="item"
          />
        </div>
      </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.id="{ item, value }">
        <span class="first-text" :id="value.id">
          <v-tooltip bottom v-if="item.id.detail.merged_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value.id }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>Merged with Order:{{ item.id.detail.merged_order }}</span>
          </v-tooltip>
          <div v-else>{{ value.id }}</div>
        </span>
      </template>

      <template #item.systemId="{ item, value }">
        <span class="second-text" :id="value.systemId">
          <v-tooltip bottom v-if="item.id.detail.split_order">
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                "
              >
                <span>{{ value }}</span>
                <v-icon>mdi-file</v-icon>
              </div>
            </template>
            <span>{{ item.id.detail.split_order }}</span>
          </v-tooltip>
          <div v-else>{{ value }}</div>
        </span>
      </template>

      <template #item.action="{ item }">
        <v-menu left transition="scale-transition" offset-y bottom rounded>
          <template v-slot:activator="{ on, attrs }">
            <a
              v-bind="attrs"
              v-on="on"
              class="btn btn-light-light btn-hover-info show poppins min-w-100px min-w-md-110px"
            >
              Actions
              <span class="svg-icon svg-icon-5 m-0">
                <v-icon small>mdi-chevron-down</v-icon>
              </span>
            </a>
          </template>
          <v-card class="poppins py-1" elevation="1">
            <div v-if="permissionChecker('reopen')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.reopen"
                :class="
                  !item.id.detail.actions.reopen ? 'text-muted' : 'text-dark'
                "
                @click="() => actionReopen(item.id.id)"
                text
              >
                Remove POD
              </v-btn>
            </div>

            <div v-if="permissionChecker('update_order_status')">
              <v-btn
                class="w-100 text-left px-0 px-sm-2"
                :disabled="!item.id.detail.actions.update_order_status"
                :class="
                  !item.id.detail.actions.update_order_status
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => updateStatus(item)"
                text
                >Update Status
              </v-btn>
            </div>

            <div v-if="permissionChecker('update')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.update"
                :class="
                  !item.id.detail.actions.update ? 'text-muted' : 'text-dark'
                "
                @click="() => editItem(item.id.id)"
                text
                >Edit
              </v-btn>
            </div>

            <div v-if="permissionChecker('view')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.show"
                :class="
                  !item.id.detail.actions.show ? 'text-muted' : 'text-dark'
                "
                @click="() => showItemDetail(item)"
                text
                >View
              </v-btn>
            </div>

            <div v-if="permissionChecker('update_3pl_awb')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.update_3pl_awb"
                :class="
                  !item.id.detail.actions.update_3pl_awb
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => update3PLAWB(item.id.id)"
                text
                >Update last mile
              </v-btn>
            </div>
            <div v-if="permissionChecker('update_order_client')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.update_order_client"
                :class="
                  !item.id.detail.actions.update_order_client
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => updateOrderClient(item.id.id)"
                text
                >Update No of box
              </v-btn>
              <NumberOfBoxModal
                ref="updateOrderClientModal"
                :refresher="updateTableContent"
              />
            </div>

            <div v-if="permissionChecker('upload_doc')">
              <v-btn
                class="w-100 text-left text-dark"
                @click="() => showUploadDocModal(item)"
                text
                >Upload doc
              </v-btn>
            </div>

            <div v-if="permissionChecker('order_confirm')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.confirmation"
                :class="
                  !item.id.detail.actions.confirmation
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => handleConfirmOrder(item.id.id)"
                text
                >Confirm
              </v-btn>
            </div>

            <div v-if="permissionChecker('order_cancel')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.order_cancel_in_action"
                :class="
                  !item.id.detail.actions.order_cancel_in_action
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => handleCancelOrder(item.id.id)"
                text
                >Cancel
              </v-btn>
            </div>

            <div v-if="permissionChecker('mark_as_delivered')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.mark_as_delivered"
                :class="
                  !item.id.detail.actions.mark_as_delivered
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => markAsDeliveredAction(item.systemId)"
                text
                >Mark As Delivered
              </v-btn>
            </div>

            <div v-if="permissionChecker('generate_external_invoice')">
              <v-btn
                class="w-100 text-left"
                :disabled="!item.id.detail.actions.generate_external_invoice"
                :class="
                  !item.id.detail.actions.generate_external_invoice
                    ? 'text-muted'
                    : 'text-dark'
                "
                @click="() => generateExternalInvoice(item.systemId)"
                text
                >Generate External Invoice
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
      <!-- column end -->
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <EditItem
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="editModal"
      :showNextOrder="showNextOrder"
      :showPrevOrder="showPrevOrder"
      :extraData="extraData"
      :itemForAction="editItemIdData"
    ></EditItem>
    <QueueUploadModal
      :refresher="updateTableContent"
      :pageLoader="pageLoad"
      ref="uploadDoc"
      url="/fulfillment/orders/upload_doc"
      :isIdRequired="true"
    />
    <StatusUpdateModal
      ref="stausUpdate"
      v-if="status_update_data"
      :statusDetails="status_update_data"
      :refresher="updateTableContent"
      :key="status_update_data.id"
    />
    <CreateReturnAsnModal
      ref="createReturnAsn"
      :items="selected"
    ></CreateReturnAsnModal>

    <Update3PLAWBModal
      ref="update3PLAWBModal"
      :refresher="updateTableContent"
    />
    <v-dialog v-model="dialog2" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason == 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable persistent max-width="400px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  EXPORT_TABLE_DATA,
  SET_TABLE_FILTER,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  SET_TABLE_SORTBY,
  SET_TABLE_SORTORDER,
  UPDATE_TABLE_DATA,
  UPDATE_TABLE_STRUCTURE,
} from "@/core/services/store/orders.module";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import getPageWidthHeight from "@/own/mixins/getPageWidthHeight";

import EditItem from "@/own/components/fulfillment/orders/EditItem.vue";
import AddItem from "@/own/components/fulfillment/orders/AddItem.vue";
import Print from "@/own/components/fulfillment/orders/Print.vue";
import Others from "@/own/components/fulfillment/orders/Others.vue";

import ChangeStatus from "@/own/components/fulfillment/orders/ChangeStatus.vue";
import NumberOfBoxModal from "@/own/components/fulfillment/orders/NumberOfBoxModal.vue";
import Update3PLAWBModal from "@/own/components/fulfillment/orders/popup/Update3PLAWBModal";

import ComponentSelector from "@/own/components/datatable/ComponentSelector.vue";

import Pagination from "@/own/components/pagination/Pagination.vue";
import Swal from "sweetalert2";
import DatatableDetail from "@/own/components/datatable/DatatableDetailNew";
import ApiService from "@/core/services/api.service";
import FilterSidebar from "@/own/components/sidebars/FilterSidebar";
import ExportSidebar from "@/own/components/sidebars/ExportSidebar";
import QueueUploadModal from "@/own/components/Modals/QueueUploadModal";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import ActionsButton from "@/own/components/fulfillment/orders/ActionsButton";
import StatusUpdateModal from "@/own/components/fulfillment/orders/StatusUpdateModal.vue";
import CreateReturnAsnModal from "@/own/components/fulfillment/orders/CreateReturnAsnModal.vue";

export default {
  name: "DataTable",
  mixins: [getPageWidthHeight],
  components: {
    ActionsButton,
    QueueUploadModal,
    DatatableDetail,
    FilterSidebar,
    ExportSidebar,
    Pagination,
    Print,
    Others,
    ComponentSelector,
    EditItem,
    AddItem,
    ChangeStatus,
    StatusUpdateModal,
    Update3PLAWBModal,
    NumberOfBoxModal,
    CreateReturnAsnModal,
  },
  props: {
    disableSelectAllCheckbox: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data: () => ({
    toolBarVisionState: false,
    selected: [],
    allSelected: false,
    offset: true,
    columnsModal: false,

    editItemId: null,
    updateStatusItemId: null,
    dialog2: false,
    dialog3: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    menu2: false,
    status_filter: null,
    orderForStatusUpdate: null,
    status_update_data: null,
    isUpdateStatusItemsMultiple: true,
  }),
  async beforeMount() {
    const tableStructurePromise = this.$store.dispatch(
      UPDATE_TABLE_STRUCTURE,
      {}
    );
    const tableDataPromise = this.$store.dispatch(
      UPDATE_TABLE_DATA,
      this.$store.getters.getORDERSTableState
    );

    await Promise.all([tableStructurePromise, tableDataPromise]);
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  },
  computed: {
    status_filter_data: function () {
      // console.log(this.filters);
      return this.filters.filter((data) => data.name === "orderStatus")[0]
        .values;
    },
    isToolbarVisiable: function () {
      return this.$vuetify.breakpoint.mdAndUp ? true : this.toolBarVisionState;
    },
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getORDERSTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getORDERSTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getORDERSTableData.total;
    },
    pageCount: function () {
      return this.$store.getters.getORDERSTableData.last_page;
    },
    //filters
    filters: function () {
      let filters = null;
      try {
        filters = this.$store.getters.getORDERSTableFilters;
      } catch {
        filters = [];
      }
      return filters;
    },
    actions: function () {
      return [
        {
          title: "View",
          method: "showItemDetail",
          isVisible: this.permissionChecker("update"),
        },
        // {
        //   title: "Edit",
        //   method: "editItem",
        //   isVisible: this.permissionChecker("update"),
        // },
        {
          title: "Remove",
          method: "removeItem",
          isVisible: this.permissionChecker("destroy"),
        },
        {
          title: "Upload doc",
          method: "showUploadDocModal",
          isVisible: this.permissionChecker("upload_doc"),
        },
      ];
    },
    table_permissions: function () {
      if (this.$store.getters.getORDERSTablePermissions) {
        return this.$store.getters.getORDERSTablePermissions.map(function (
          perm
        ) {
          return perm.name;
        });
      } else {
        return false;
      }
    },
    table_properties: function () {
      return this.$store.getters.getORDERSTableProperties;
    },
    itemPerPage: function () {
      return this.$store.getters.getORDERSTableState.per_page;
    },
    orderBy: {
      get: function () {
        return this.$store.getters.getORDERSTableState.sortBy;
      },
      set: function (newValue) {
        if (newValue) {
          this.$store.commit(SET_TABLE_SORTBY, newValue);
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getORDERSTableState
          );
        } else {
          this.$store.commit(SET_TABLE_SORTBY, "");
          this.$store.dispatch(
            UPDATE_TABLE_DATA,
            this.$store.getters.getORDERSTableState
          );
        }
        this.$store.commit(SET_PAGE_LOADING, false);
      },
    },
    sortOrder: {
      get: function () {
        let sortOrder = true;
        if (this.$store.getters.getORDERSTableState.sortOrder === "asc") {
          sortOrder = false;
        }
        return sortOrder;
      },
      set: function (newValue) {
        if (newValue === true) {
          this.$store.commit(SET_TABLE_SORTORDER, "desc");
        } else {
          this.$store.commit(SET_TABLE_SORTORDER, "asc");
        }
        this.$store
          .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getORDERSTableState)
          .then(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      },
    },
    headers: function () {
      /**
       * @type {[
       {
       value: string,
       text: string,
       type: string,
       sortable: string,
       exportable: string,
       visible: string,
       mobile_visible: string,
       align: string,
       itemClass: string,
       width: string,
       class: string,
       export_order: integer,
       }
       ]}
       */
      let headers = false;
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.$store.getters.getORDERSTableHeaders) {
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true && col.mobile_visible === true
          );
        }
      } else {
        if (this.$store.getters.getORDERSTableHeaders) {
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true
          );
        } else {
          this.$store.dispatch(UPDATE_TABLE_STRUCTURE, {});
          headers = this.$store.getters.getORDERSTableHeaders.filter(
            (col) => col.visible === true
          );
        }
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        header.width = undefined;
        if (header.value === "aging_day")
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        if (header.value === "action") {
          header.class =
            "pr-10 text-end text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
    exportHeaders: function () {
      let headers = null;
      try {
        headers = this.$store.getters.getORDERSTablePermissions.filter(
          (col) => col.name === "export"
        )[0].columns;
      } catch (error) {
        headers = [];
      }
      return headers;
    },
    types: function () {
      return this.$store.getters.getORDERSPrintTypes;
    },

    actionsButtonTypes: function () {
      let types = [];

      const typeNames = [
        "Reset to confirmed",
        "Reset to shipped",
        "Remove Shipped",
        "Sync with WMS",
        "Update aging",
        "3PL status sync",
        "Remove POD",
        "Unbundle",
        "Cancel",
        "Delete",
        "Create RVP",
        "Create RTO",
        "RTS Enable",
        "RTS Disable",
        "Create return ASN",
        "Reset Carrier Retry",
        "Mark As Delivered",
        "Mark As Confirmed",
        "Generate External Invoice",
      ];

      const typesUrl = [
        "order_reset_to_confirmed",
        "order_reset_to_shipped",
        "order_remove_shipped",
        "order_sync_with_wms",
        "order_update_aging",
        "order_3pl_status_sync",
        "remove_pod",
        "unbundle",
        "order_cancel",
        "destroy",
        "order_rvp_create",
        "order_rto_create",
        "order_rts_add",
        "order_rts_remove",
        "return_order_asn_create",
        "order_reset_retry_counter",
        "mark_as_delivered",
        "mark_as_confirmed",
        "generate_external_invoice",
      ];

      typesUrl.forEach((item, index) => {
        if (this.permissionChecker(item))
          types.push({
            name: typeNames[index],
            url: item,
          });
      });

      return types;
    },
    items: function () {
      let items = [];
      if (this.$store.getters.getORDERSTableData) {
        items = this.$store.getters.getORDERSTableData.data;
      }
      return items;
    },
    extraData() {
      return this.$store.getters.getORDERSExtraData;
    },
    editItemIdData() {
      return this.editItemId;
    },
    // call_statuses() {
    //   return this.$store.getters.getORDERSCallStatuses;
    // },
    getQFilterStatusLabel() {
      return this.status_filter
        ? this.status_filter_data.filter(
            (data) => data.index == this.status_filter
          )[0].text
        : "Status";
    },
    getIsUpdateStatusItemsMultiple() {
      return this.isUpdateStatusItemsMultiple;
    },
    ordersPermissions: function () {
      return this.$store.getters.getORDERSTablePermissions;
    },
  },
  methods: {
    toggelCreateAsnModal() {
      if (!this.selected.length) {
        Swal.fire({
          title: "No Order Selected",
          text: `Select some orders to create ASN`,
          icon: "warning",
          showConfirmButton: true,
        });
        return;
      }
      this.$refs.createReturnAsn.toggleModal();
    },
    onStatusFilterChange(val) {
      this.$refs.dropdown.hide(true);
      this.status_filter = val;
      // this.resetFilterValues();
      this.$refs.filterSidebar.$refs.filter.clearFiltersState();
      if (val === null) {
        this.setTableFiltersValues([]);
        this.$nextTick(() => {
          this.submitFilterValues();
        });
        return;
      }
      this.setTableFiltersValues([{ name: "orderStatus", value: val }]);
      this.$nextTick(() => {
        this.submitFilterValues();
      });
    },
    showCancelModal() {
      if (!this.dialog3) {
        this.cancel_reason = null;
        if (this.selected.length > 0) {
          this.dialog3 = !this.dialog3;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog3 = !this.dialog3;
      }
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoad(true);
        this.dialog3 = false;
        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    showHoldOnModal() {
      if (!this.dialog2) {
        this.holdOn_reason = null;
        this.holdOn_date = null;
        if (this.selected.length > 0) {
          this.dialog2 = !this.dialog2;
        } else {
          Swal.fire({
            title: "Warning",
            text: `Please, select item!`,
            icon: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
        }
      } else {
        this.dialog2 = !this.dialog2;
      }
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoad(true);
        this.dialog2 = false;

        const data = {
          order_ids: this.selected.map((item) => item.id.id),
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `On Hold reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoad(false);
            this.updateTableContent();
          })
          .catch(() => {
            this.pageLoad(false);
          });
      }
    },
    deleteItem(item) {
      const index = this.selected.findIndex((el) => el.id === item.id);
      index > -1 && this.selected.splice(index, 1);
    },
    deleteAllItems() {
      this.selected.splice(0, this.selected.length);
    },
    selectOne(event) {
      this.allSelected && this.deleteItem(event.target.value);

      this.allSelected = false;
    },
    selectAll(event) {
      this.deleteAllItems();
      if (event.target.checked) {
        let innerItems;
        if (this.items.length < this.getItemPerPage) {
          innerItems = this.items;
        } else {
          const startFrom =
            this.getItemPerPage * this.getpageNumber - this.getItemPerPage;
          innerItems = this.items.slice(startFrom, this.getItemPerPage);
        }
        innerItems.map((item) => {
          this.selected.push(item);
        });
      }
    },
    onResize() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.toolBarVisionState = false;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        this.toolBarVisionState = true;
      }
    },
    actionReopen(id) {
      this.pageLoad(true);
      let data = { id: id };

      const isConfirmationRequired = this.ordersPermissions.find(
        (permission) => permission.name === "remove_pod"
      ).is_confirmation_required;

      if (isConfirmationRequired) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            ApiService.post("/fulfillment/orders/reopen", data)
              .then(() => {
                Swal.fire("Modified!", "Order modified!", "success");
                this.updateTableContent();
              })
              .catch(() => {
                this.pageLoad(false);
              });
          }
        });
      }
    },
    // actionCall(item) {
    //   this.$refs.orderCall.$props.item = item;
    //   this.$refs.orderCall.toggleModal();
    // },
    // pagination
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    // pagination end
    // filters
    setTableFiltersValues(val) {
      this.$store.commit(SET_TABLE_FILTER, val);
    },
    submitFilterValues() {
      this.$store.commit(SET_PAGE_LOADING, true);
      let state = { ...this.$store.getters.getORDERSTableState };
      state.page = 1;
      this.$store
        .dispatch(UPDATE_TABLE_DATA, state)
        .then(() => {
          this.$store.commit(SET_TABLE_PAGE, 1);
          this.$store.commit(SET_PAGE_LOADING, false);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    resetFilterValues() {
      this.$store.commit(SET_TABLE_FILTER, []);
      this.$store.commit(SET_PAGE_LOADING, true);
      this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getORDERSTableState)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.selected = [];
    },
    // filters end
    /**
     * checks permission and return true/false
     * @types {boolean}
     * example "view","create","update","destroy","show","generate","status","print","activate","upload"
     **/
    permissionChecker(permType) {
      return !!this.table_permissions.includes(permType);
    },
    loadDetailData(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      let data = { order_id: id };
      ApiService.post("/fulfillment/orders/show", data)
        .then((response) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          this.$refs.otherData.setData(response.data.detail);
          this.$refs.otherData.toggleModal();
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    showAddItemModal() {
      this.$refs.addItem.toggleModal();
    },
    showItemDetail(item) {
      this.loadDetailData(item.id.id);
    },
    showUploadDocModal(item) {
      // this.$refs.uploadDoc.$props.itemForAction = item;
      this.$store.commit(SET_ITEM_FOR_ACTION, item);
      this.$refs.uploadDoc.dialog = true;
    },
    handleConfirmOrder(id) {
      const isConfirmationRequired = this.ordersPermissions.find(
        (permission) => permission.name === "order_confirm"
      ).is_confirmation_required;

      if (isConfirmationRequired) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.pageLoad(true);
            ApiService.post("/fulfillment/orders/order_confirm", {
              id,
            })
              .then(() => {
                Swal.fire("Modified!", "Order status updated!", "success");
                this.updateTableContent();
              })
              .catch(() => {
                this.pageLoad(false);
              });
          }
        });
      }
    },
    handleCancelOrder(id) {
      const isConfirmationRequired = this.ordersPermissions.find(
        (permission) => permission.name === "order_cancel"
      ).is_confirmation_required;

      if (isConfirmationRequired) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Confirm!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.pageLoad(true);
            ApiService.post("/fulfillment/orders/order_cancel_in_action", {
              id,
            })
              .then(() => {
                Swal.fire("Modified!", "Order status updated!", "success");
                this.updateTableContent();
              })
              .catch(() => {
                this.pageLoad(false);
              });
          }
        });
      }
    },
    markAsDeliveredAction(systemId) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/orders/mark_as_delivered", {
        orders: [systemId],
      })
        .then(({ data }) => {
          Swal.fire({
            title: data.success ? "Success!" : "Warning!",
            text: data.messages[0],
            icon: data.success ? "success" : "warning",
            timer: 2500,
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    markAsConfirmedAction(systemId) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/orders/mark_as_confirmed", {
        orders: [systemId],
      })
        .then(({ data }) => {
          Swal.fire({
            title: data.success ? "Success!" : "Warning!",
            text: data.messages[0],
            icon: data.success ? "success" : "warning",
            timer: 2500,
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    generateExternalInvoice(systemId) {
      this.pageLoad(true);
      ApiService.post("/fulfillment/orders/generate_external_invoice", {
        orders: [systemId],
      })
        .then(({ data }) => {
          Swal.fire({
            title: data.success ? "Success!" : "Warning!",
            text: data.messages[0],
            icon: data.success ? "success" : "warning",
            timer: 2500,
          });
          this.updateTableContent();
        })
        .catch(() => {
          this.pageLoad(false);
        });
    },
    // eslint-disable-next-line no-unused-vars
    showDetail(val, { item, isMobile = true }) {
      this.loadDetailData(item.id.id);
    },
    isAnyFilterSelected() {
      let isSelected = false;
      if (this.$store.getters.getORDERSTableState.filters.length > 0) {
        isSelected = true;
      }
      return isSelected;
    },
    // exports
    downloadItem(columns) {
      if (this.isAnyFilterSelected()) {
        this.$store.commit(SET_PAGE_LOADING, true);
        // console.log("order table state", this.$store.getters.getORDERSTableState);
        this.$store
          .dispatch(EXPORT_TABLE_DATA, {
            columns: columns,
            filters: this.$store.getters.getORDERSTableState.filters,
          })
          .then(() => {
            const link = document.createElement("a");
            link.href = this.$store.getters.getORDERSExportUrl;
            link.download = "Export.csv";
            link.click();
            setTimeout(() => {
              this.$store.commit(SET_PAGE_LOADING, false);
            }, 1500);
            this.$refs.exportSidebar.$refs.export.toggleModal();
            link.remove();
          });
      } else {
        this.$refs.exportSidebar.$data.exportSidebar = false;
        Swal.fire({
          title: "Warning",
          text: "Please, select at least one filter!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    },
    // exports end
    handle_function_call(function_name, prop) {
      this[function_name](prop);
    },
    removeItem(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pageLoad(true);
          ApiService.post(`/authorizations/clients/${item.id}/destroy`)
            .then(() => {
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
              this.updateTableContent();
            })
            .catch(() => {
              this.pageLoad(false);
            });
        }
      });
    },
    pageLoad(state) {
      this.$store.commit(SET_PAGE_LOADING, state);
    },
    toggleColumnsModal() {
      this.columnsModal = !this.columnsModal;
    },

    showUploadModal() {
      // this.$refs.upload.toggleUploadModal();
      this.$router.push({
        name: "settings.uploads",
        query: { type: this.$store.getters.getORDERSUploadType },
      });
    },
    async updateTableContent() {
      this.pageLoad(true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, this.$store.getters.getORDERSTableState)
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.$forceUpdate();
      this.selected = [];
      this.pageLoad(false);
    },

    async findNextOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -1;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index + 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (parseInt(this.getItemPerPage) == nextIdIndex) {
        // console.log("last item");
        if (this.pageCount > this.getPageNumber) {
          // console.log("next page exist");
          this.setPageNumber(this.getPageNumber + 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGAUDITORDERSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          // console.log("next item id is", this.items[0].id.id);
          nextId = this.items[0].id.id;
        }
      } else if (this.items.length - 1 < nextIdIndex) {
        throw TypeError("there is no next order");
      } else {
        try {
          nextId = this.items[nextIdIndex].id.id;
        } catch (e) {
          // console.log(e);
        }
      }
      // console.log("next id is", this.items[nextIdIndex].id.id, nextId);
      return nextId;
    },
    async findPrevOrderId(id) {
      // TODO: find and return next order id
      let nextIdIndex = -2;
      let nextId = null;
      this.items.forEach((element, index) => {
        if (element.id.id === id) {
          nextIdIndex = index - 1;
        }
      });
      // console.log("index is", nextIdIndex);
      if (nextIdIndex < 0) {
        // console.log("first item");
        if (this.getPageNumber > 1) {
          // console.log("prev page exist");
          this.setPageNumber(this.getPageNumber - 1);
          await this.$store
            .dispatch(
              UPDATE_TABLE_DATA,
              this.$store.getters.getPENDINGAUDITORDERSTableState
            )
            .then(() => {
              this.$forceUpdate();
              // console.log("on update", this.items[0].id.id);
            });
          nextId = this.items[parseInt(this.getItemPerPage) - 1].id.id;
        }
      } else {
        try {
          nextId = this.items[nextIdIndex].id.id;
        } catch (e) {
          // console.log(e);
          throw TypeError("Can not find previous order");
        }
      }
      // console.log(nextIdIndex, nextId, this.getPageNumber, this.getItemPerPage);
      return nextId;
    },
    showNextOrder(id) {
      // console.log("current id", id);
      this.$refs.editModal.toggleModal();
      this.findNextOrderId(id)
        .then((data) => {
          // console.log("find next order ", data);
          if (data) {
            this.editItem(data);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    showPrevOrder(id) {
      this.$refs.editModal.toggleModal();
      this.findPrevOrderId(id)
        .then((data) => {
          if (data) {
            this.editItem(data);
            this.updateTableContent();
          }
        })
        .catch(() => {
          // console.log(e);
          this.updateTableContent();
        });
    },
    editItem(id) {
      this.editItemId = id;
      this.$nextTick(() => {
        this.$refs.editModal.toggleModal();
      });
    },
    update3PLAWB(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/fulfillment/orders/update_3pl_awb", { id })
        .then(({ data }) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          if (data.success) this.$refs.update3PLAWBModal.toggleModal(data);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    updateOrderClient(id) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/fulfillment/orders/update_order_client", { id })
        .then(({ data }) => {
          this.$store.commit(SET_PAGE_LOADING, false);
          if (data.success) this.$refs.updateOrderClientModal.toggleModal(data);
        })
        .catch(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    noOrderSelectedWarning() {
      Swal.fire({
        title: "Warning",
        text: "Please, select at least one order!",
        icon: "warning",
        showConfirmButton: false,
        timer: 2500,
      });
    },
    updateStatus(item = null, is_multiple = false) {
      if (is_multiple) {
        if (this.selected.length === 0) {
          this.noOrderSelectedWarning();
          return;
        }
        if (this.selected.length === 1) {
          this.updateStatusItemId = this.selected[0].systemId;
          this.isUpdateStatusItemsMultiple = false;
        } else {
          this.updateStatusItemId = this.selected;
          this.isUpdateStatusItemsMultiple = true;
        }
      } else {
        this.updateStatusItemId = item.systemId;
        this.isUpdateStatusItemsMultiple = false;
      }
      this.$nextTick(() => {
        this.$refs.changeStatus.toggleModal();
      });
    },
  },
};
</script>
<style scoped>
.q-status-menu {
  max-height: 350px;
  overflow-y: scroll;
}

.drop-item {
  font-weight: 500;
  color: rgb(98, 98, 98);
}

.drop-item:hover {
  font-weight: 600;
  background-color: #eee5ff;
  color: #8950fcff !important;
}

.drop-text {
  font-size: 9px;
}
</style>
